import { Prettify } from '@util/types/utility-types';

const tmpVar = typeof (1 as any);
type Tmp = typeof tmpVar | 'array' | 'null';
type Typie = Prettify<Tmp>;

/**
 * A class with static methods that can get or set values in a given object with a given structure, which is represented
 * as a string.
 */
export class ObjectTraverser {

  /**
   * traverses a given object according to a given path and will call a given function at the end of that path,
   * (optional: a force flag can be set to true, so that the path will be created if it does not exists)
   * @example ObjectTraverser.traverse<string>(config, 'products[0].price["formattedValue"]', (ref, attr) => console.log('Price of 1st', ref[attr]));
   */
  static traverseTo<T = any>(object: any, path: string, finalFn: (ref: any, attr: string | number) => T, force?: boolean) {
    const carr = ObjectTraverser.getComposedPath(object, path, force);
    const targetItem = carr.length > 1 ? carr.slice(-2, -1)[0] : carr[0];
    return finalFn(targetItem.data, targetItem.accessor);
  }

  static getComposedPath(object: any, path: string, force?: boolean) {
    const accessorArr = ObjectTraverser.transformPathToArray(path);

    let i: number;
    let cpath = '';
    let cdata = object;
    let typie = this.getTypie(object);

    const stack: {
      path: string;
      data: any;
      type: Typie;
      accessor: typeof accessorArr[number];
    }[] = [];

    stack.push({data: cdata, path: '', type: typie, accessor: accessorArr[0]});

    if (accessorArr.length === 1 && accessorArr[0] === '') {
      return stack;
    }

    for (i = 0; i < accessorArr.length; i++) {

      const isLastLoop = i === accessorArr.length - 1;
      typie = this.getTypie(cdata[accessorArr[i]]);

      if (force && !isLastLoop && typie !== 'array' && typie !== 'object') {

        // if next accessor part is a string
        // current data needs to be an object
        if (typeof accessorArr[i + 1] === 'string') {
          cdata[accessorArr[i]] = {};
        }

        // if next accessor part is a number
        // current data needs to be an array
        if (typeof accessorArr[i + 1] === 'number') {
          cdata[accessorArr[i]] = [];
        }

      }

      cdata = cdata[accessorArr[i]];
      typie = this.getTypie(cdata);

      if (typeof accessorArr[i] === 'string') {
        cpath += ((cpath ? '.' : '') + accessorArr[i]);
      }

      if (typeof accessorArr[i] === 'number') {
        cpath += '[' + accessorArr[i] + ']';
      }

      stack.push({
        data: cdata,
        path: cpath,
        type: typie,
        accessor: isLastLoop ? '' : accessorArr[i + 1]
      });
    }

    return stack;
  }

  /**
   * gets the value of a given object at the given path.
   * (optional: a force flag can be set to true, so that the path will be created if it does not exists.
   * the returned value will be null)
   * @example const price = ObjectTraverser.getValue<string>(config, 'products[0].price["formattedValue"]');
   */
  static getValue<T = any>(object: any, path: string, force?: boolean): T {
    return ObjectTraverser.traverseTo<T>(object, path, (ref, attr) => {
      return ref[attr] as T;
    }, force);
  }

  /**
   * sets the value of a given object at the given path.
   * (optional: a force flag can be set to true, so that the path will be created if it does not exists.)
   * @example ObjectTraverser.setValue<string>(config, 'products[0].price["formattedValue"]', '13.99 €')
   */
  static setValue(object: any, path: string, value: any, force?: boolean): void {
    ObjectTraverser.traverseTo<void>(object, path, (ref, attr) => {
      ref[attr] = value;
    }, force);
  }

  /**
   * traversing through data and calls the callback function with every attribute and item.
   * Depth-First
   * @returns if traversing through the data was prematurely stopped or not
   */
  static traverse(data: any, callbackFn: (value: any, valueType: Typie, path: string, waver: { waveStop: () => void; }) => void, algorithm: 'breadth-first' | 'depth-first' = 'breadth-first') {
    return algorithm === 'breadth-first' ? this.traverseBreadthFirst(data, callbackFn) : this.traverseDepthFirst(data, callbackFn);
  }

  /**
   * traversing through data and calls the callback function with every attribute and item.
   * recursive Depth-First
   * @returns if traversing through the data was prematurely stopped or not
   */
  private static traverseDepthFirst(data: any, callbackFn: (value: any, valueType: Typie, path: string, waver: { waveStop: () => void; }) => void) {

    const refSet = new Set<Record<string, any>>();
    let stopFlag = false;
    const waver = { waveStop: () => stopFlag = true };

    const recFn = (newData: any, newPath: string) => {

      let t: Typie = this.getTypie(newData);
      let i: number;

      if (newData && t === 'object') {
        refSet.add(newData);
      }

      callbackFn(newData, t, newPath, waver);

      if (t === 'array') {
        for (i = 0; !stopFlag && i < (newData as any[]).length; i++) {
          const target = (newData as any[])[i];
          const targetTypie = this.getTypie(target);
          const itIsSaveToRecursivelyCallTheTarget = (targetTypie !== 'object' || !refSet.has(target));
          if (itIsSaveToRecursivelyCallTheTarget) {
            recFn(target, newPath + '[' + i + ']');
          }
        }
      }

      if (newData && t === 'object') {

        const keys = Object.keys(newData);

        for (i = 0; !stopFlag && i < keys.length; i++) {
          const target = newData[keys[i]];
          const targetTypie = this.getTypie(target);
          const itIsSaveToRecursivelyCallTheTarget = (targetTypie !== 'object' || !refSet.has(target));
          if (itIsSaveToRecursivelyCallTheTarget) {
            recFn(target, newPath + (newPath.length > 0 ? '.' : '') + keys[i]);
          }

        }
      }

    };

    recFn(data, '');

    return stopFlag;
  }

  /**
   * traversing through data and calls the callback function with every attribute and item.
   * iterative Breadth-First
   * @returns if traversing through the data was prematurely stopped or not
   */
  private static traverseBreadthFirst(data: any, callbackFn: (value: any, valueType: Typie, path: string, waver: { waveStop: () => void; }) => void) {

    const refSet = new Set<Record<string, any>>();
    const q: {data: any; typie: Typie; path: string;}[] = [];
    let stopFlag = false;
    const waver = { waveStop: () => stopFlag = true };

    const dataTypie = this.getTypie(data);
    q.unshift({data, typie: dataTypie, path: ''});

    while(q.length > 0 && !stopFlag) {
      const v = q.shift();

      callbackFn(v.data, v.typie, v.path, waver);

      if (v.typie === 'object') {
        refSet.add(v.data);
      }

      let i: number;

      if (!stopFlag && v.typie === 'array') {

        for (i = 0; i < (v.data as any[]).length; i++) {
          const target = (v.data as any[])[i];
          const targetTypie = this.getTypie(target);

          const hasNeverBeenVisitedBefore = (targetTypie !== 'object' || !refSet.has(target));
          if (hasNeverBeenVisitedBefore) {
            q.push({data: target, typie: targetTypie, path: v.path + '[' + i + ']'});
          }
        }

      }

      if (!stopFlag && v.data && v.typie === 'object') {

        const keys = Object.keys(v.data);
        for (i = 0; i < keys.length; i++) {
          const target = v.data[keys[i]];
          const targetTypie = this.getTypie(target);
          const hasNeverBeenVisitedBefore = (targetTypie !== 'object' || !refSet.has(target));
          if (hasNeverBeenVisitedBefore) {
            q.push({data: target, typie: targetTypie, path: v.path + (v.path.length > 0 ? '.' : '') + keys[i]});
          }
        }
      }

    }

    return stopFlag;
  }

  private static transformPathToArray(path: string): (string | number)[] {
    const parts: (string | number)[] = [];
    const dots = path.split('.');
    dots.forEach(dotpart => {
      if (dotpart.includes('[')) {
        const innerParts = ObjectTraverser.innerProcess(dotpart);
        parts.push(...innerParts);
      } else {
        parts.push(dotpart);
      }
    });
    return parts;
  }

  static findPathsOf(target: any, object: any, stopWithFirst?: boolean, algorithm?: 'breadth-first' | 'depth-first') {
    const paths: string[] = [];

    this.traverse(object, (value, _, path, waver) => {
      if (value === target) {
        paths.push(path);
        if (stopWithFirst) {
          waver.waveStop();
        }
      }
    }, algorithm);

    return paths;
  }

  private static innerProcess(str: string): (string | number)[] {

    const openIndexes: number[] = [];
    const closedIndexes: number[] = [];

    const innerParts: (string | number)[] = [];

    let i: number;
    for (i = 0; i < str.length; i++) {
      if (str[i] === '[') {
        openIndexes.push(i);
      }
      if (str[i] === ']') {
        closedIndexes.push(i);
      }
    }

    openIndexes.forEach((oi, i) => {
      const ci = closedIndexes[i];

      if (i === 0 && ci > 0) {
        const textBefore = str.slice(0, oi);
        innerParts.push(textBefore);
      }

      const inside = str.slice(oi + 1, ci).replace(/'/gi, '"');
      const parsed = JSON.parse(inside) as (string | number);
      innerParts.push(parsed);
    });

    return innerParts;
  }

  static getTypie(targetData: any): Typie {
    let t: Typie = typeof targetData;
    if (t === 'object') {
      t = !targetData ? 'null' : Array.isArray(targetData) ? 'array' : t;
    }
    return t;
  }

}
